const debounce = require("lodash.debounce");
import _ from "lodash";
import Vue from "vue";
import m from "moment";
import pdf from "vue-pdf";
import { extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  regex,
  min,
  numeric,
  alpha_spaces,
} from "vee-validate/dist/rules";
import PreviewModal from "@/components/reusable/PreviewModal";
import { mapGetters, mapActions, mapState } from "vuex";

extend("required", { ...required, message: "This field is required" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters",
});
extend("password_strength", {
  ...regex,
  message: "Password must have capitals, numbers and special characters",
});
extend("confirmed", {
  ...confirmed,
  message: "This field should match password",
});
extend("email", { ...email, message: "Invalid email" });
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("alpha_spaces", {
  ...alpha_spaces,
  message: "Only Alphabets are allowed",
});
extend("no_future_date", (value) => {
  let input_date = new Date(value);
  let today_date = new Date();
  if (input_date > today_date) {
    return "Given date should not be greater than today!";
  }
  return true;
});
extend("dob_check", {
  params: ["dob"],
  validate(value, { dob }) {
    let birth_date = new Date(dob);
    let input_date = new Date(value);
    if (!(input_date < birth_date)) {
      return true;
    }
  },
  message: "Given date should be between birthdate & today!",
});
extend("higher_qual_date_check", {
  params: ["first_qualified_date"],
  validate(value, { first_qualified_date }) {
    let first_qual_date = new Date(first_qualified_date);
    let input_date = new Date(value);
    if (!(input_date < first_qual_date)) {
      return true;
    }
  },
  message: "Given date should be greater than first qualification date!",
});
extend("future_date", {
  validate: (value) => {
    const inputDate = new Date(value);
    const todayDate = new Date();
    if (inputDate <= todayDate) {
      return "Given date should be in the future!";
    }
    return true;
  },
});
extend('end_date_greater', {
  validate(value, { startDateValue }) {
    const startDate = new Date(startDateValue);
    const endDate = new Date(value);

    return endDate > startDate;
  },
  params: ['startDateValue'],
  message: 'The end date must be greater than the start date.',
});

import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import PhoneInputwithCountry from "@/components/reusable/Fields/PhoneInputwithCountry";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import DateInput from "@/components/reusable/Fields/DateInput";
import UploadDocument from "@/containers/CandidateProfile/UploadDocument";
import Modal from "@/components/reusable/Modal";
import { LOGIN_URL, getLocalOrgDetail, Variant } from "@/helpers/helper";
import { CONSTANT as CONST, generateLocalFileUrl } from "@/helpers/helper";
import PasswordInput from "@/components/reusable/Fields/PasswordInput";
// import CampaignModal from "../CampaignCandidateModal.vue";

export default {
  components: {
    Select,
    TextInput,
    PhoneInput,
    PhoneInputwithCountry,
    RadioButton,
    DateInput,
    UploadDocument,
    Modal,
    PreviewModal,
    PasswordInput,
    // CampaignModal
  },
  props: {
    customer: {
      type: Number,
      default: null,
    },
    isCandidateSupplier: {
      type: Boolean,
      default: false,
    },
    organisationID: {
      type: Number,
    },
    locationID: {
      type: Number,
    },
    existingCandidate: {
      type: Object,
      default: () => { },
    },
    campaignData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      profilePhoto: {},
      isSubmitted: false,
      Variant,
      isFileError: false,
      confirmPassword: null,
      candidate: {
        preferred_contact_method: [],
        isRegisteredWhatsapp: false,
        is_have_higher_qualification: false,
        share_profile: false,
        is_private: false,
        region_ids: [{ label: "All", code: 0 }],
        preferred_country_ids: [{ label: "All", code: 0 }],
        student_distance_learning: null,
        is_additional_training_sponsored: false,
        is_additional_course: false,
      },
      payload: {
        customer_id: this.customer,
        requires_login: true,
        volunteer: false,
        retired_clinician: false,
        is_have_higher_qualification: false,
        share_profile: false,
        student_distance_learning: null,
        is_additional_course: false,
      },
      isImageSelected: false,
      fileData: {},
      url: "/img/user_default_pic.png",
      uploadFiles: [],
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      emailConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent:
          "Is this your contact email as it is different from above. Please confirm...",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      document_id: "",
      acquired_date: null,
      previewModal: {
        modalColor: "primary",
        modalTitle: "",
        isShowPopup: false,
        buttons: ["Close"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: true,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      isTermPrivacyChecked: false,
      termConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "Please accept Terms and Privacy Policy",
        isShowPopup: false,
        buttons: ["OK"],
      },
      isMobile: false,
      alreadyRegisteredModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Continue"],
      },
      displayPage: this.isCandidateSupplier ? 4 : 1,
      isLoading: false,
      emailModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent:
          "Entered email id does not match with Sign Up email! Please re-enter the correct email !",
        isShowPopup: false,
        buttons: ["Close"],
      },
      isDisabledMail: false,
      ahpStudentTrainee: false,
      // campaignModal: {
      //   isShowPopup: false,
      //   buttonLabel: "OK",
      // },
      notifyModal: {
        modalColor: "primary",
        modalTitle: "NOTIFICATION",
        modalContent:
          "Please confirm the details of your Additional Training are correct and that you would like to submit these with your registration.",
        isShowPopup: false,
        buttons: ["No, remove details", "Confirm"],
      },
      isNotify: false,
      generateLocalFileUrl,
    };
  },
  computed: {
    ...mapGetters([
      "getOrgDetail",
      "getUser",
      "getUserContactId",
      "gender",
      "locationCountryInfo",
      "nationalityCountryInfo",
      "candidateType",
      "specialityFilterByType",
      "specialityByTypeAndCountry",
      "subSpecialityFilterBySpeciality",
      "qualificationByCountryAndCandidateType",
      "issueAuthorityByQualification",
      "uploaded",
      "documentTypeExcPhoto",
      "dialingCodeWithCountryCode",
      "titles",
      "getFilteredIssueAuthority",
      "getDepartmentsByOrganisationId",
      "getSkillsByCandidateType",
      "candidateLevelFilterByTypeCountry",
      "specialInterestFilterAHP",
      "firstQualificationByCountryAndCandidateType",
      "higherQualificationByCountryAndCandidateType",
      "nurseRegistrationType",

      // get filtered issue authority based on candidate and country id
      "getFilteredIssueAuthorityByCandidateTypeAndCountry",
      "getRegisteredParent",
      "randomUuid",
      "getOrgVariant",
      "getCandidateBands",
      "getNurseSubSpeciality",
      "getNonClinicalSubSpeciality",
      "uploadResponse",
      "candidateProfile",
      "locationRegion",
      "PreferredCountryFilterByRegion",
      "campaignFieldsData",
      "fromCampaignPage",
      "getStudentQualifications",
      "getSponsorsList",
      "getCourseTestTypes",
      "locationLanguage",
      "getLanguageExam",
      "getCourseSpeciality",
      "getCourseQualifications",
      "getCourseTitleList",
      "getOrgIDFromAccessToken",
      "getUserData"
    ]),
    ...mapState([
      "regCompletedCandidate"
    ]),
    isPGMainQualification() {
      return this.candidate?.main_qualification_id?.isPG || false;
    },
    getOrgName() {
      return this.getOrgDetail?.name;
    },
    documentTypeExcPhotoCustomOrder() {
      const sortOrder = [1];
      return (
        (this.documentTypeExcPhoto &&
          this.documentTypeExcPhoto.length &&
          this.documentTypeExcPhoto
            .sort(
              (a, b) => sortOrder.indexOf(b.code) - sortOrder.indexOf(a.code)
            )
            .filter(({ code }) => ![23, 24].includes(code))) ||
        []
      );
    },
    nationalityOptions() {
      return (
        (this.nationalityCountryInfo &&
          this.candidate &&
          this.candidate["nationality_ids"] &&
          this.nationalityCountryInfo.filter((val) => {
            return !this.candidate["nationality_ids"].some(
              ({ code }) => code === val.code
            );
          })) ||
        this.nationalityCountryInfo
      );
    },
    qualByCtryAndCanTypeWithoutOther() {
      return (
        this.qualificationByCountryAndCandidateType.filter(
          ({ label }) => !label.toLowerCase().includes("other")
        ) || []
      );
    },
    firstQualByCtryAndCandType() {
      return (
        this.firstQualificationByCountryAndCandidateType.filter(
          ({ label }) => !label.toLowerCase().includes("other")
        ) || []
      );
    },
    higherQualByCtryAndCandType() {
      return (
        this.higherQualificationByCountryAndCandidateType.filter(
          ({ label }) => !label.toLowerCase().includes("other")
        ) || []
      );
    },
    options() {
      return {
        gender: this.gender || [],
        locationInfo: this.locationCountryInfo || [],
        nationality: this.nationalityOptions || [],
        titles: this.titles || [],
        dialingCode: this.dialingCodeWithCountryCode || [],
        candidateType: this.candidateTypes || [],
        speciality: this.speciality || [],
        subSpeciality:
          this.subSpecialityFilterBySpeciality({
            speciality: this.payload.speciality_id,
          }) || [],
        qualification: this.qualByCtryAndCanTypeWithoutOther || [],
        AHPqualification: this.customSortQualification || [],
        nurseHigherQualification:
          this.qualByCtryAndCanTypeWithoutOther.filter((val) =>
            [6, 7, 40].includes(val.qualification_classification_id)
          ) || [],
        issued_by:
          this.issueAuthorityByQualification({
            // country_id: this.payload.main_qualification_country_id,
            qualification_id: this.payload.main_qualification_id,
          }) || [],
        document_type: this.documentTypeExcPhotoCustomOrder || [],
        preferred_contact_method: [
          { id: "phone", label: "Phone" },
          { id: "whatsapp", label: "WhatsApp" },
          { id: "email", label: "Email" },
          { id: "sms", label: "SMS" },
        ],
        document_verification_status: [
          { id: "none", label: "None" },
          { id: "some", label: "Some" },
          { id: "all", label: "All" },
          { id: "n/a", label: "N/A" },
        ],
        specialistRegister: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        byExam: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        licence:
          this.specialityByTypeAndCountry({
            candidateType: this.payload.candidate_type_id,
            country_id: this.payload.first_qualification_country_id,
          }) || [],
        skills:
          this.getSkillsByCandidateType({
            candidate_type_id: this.payload.candidate_type_id,
          }) || [],
        departments: this.getDepartmentsByOrganisationId || [],
        first_qualification_issued_by:
          this.issueAuthorityByQualification({
            // country_id: this.payload.first_qualification_country_id,
            qualification_id: this.payload.first_qualification_id,
          }) || [],
        // first_qualification_issued_by: this.getFilteredIssueAuthorityByCandidateTypeAndCountry || [],
        haveHigherQualification: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        candidateLevel: this.candidateLevelFilterByTypeCountry({
          candidateType: this.payload?.candidate_type_id,
          country_id: null,
        }) || [],
        candidateLicenseLevelByCountry:
          this.candidateLevelFilterByTypeCountry({
            candidateType: this.payload?.candidate_type_id,
            country_id: this.payload?.license_country_id,
            is_licensing: true,
          }) || [],
        specialInterest: this.specialInterestFilterAHP({
          candidate_type_id: this.payload.candidate_type_id,
          speciality: null,
        }),
        firstQualification: this.firstQualByCtryAndCandType,
        higherQualification: this.higherQualByCtryAndCandType,
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        candidateBands: this.getCandidateBands,
        nurseSubSpeciality: this.getNurseSubSpeciality,
        nonClinicalSubSpeciality: this.getNonClinicalSubSpeciality,
        preferredRegion: this.sortOrganisationRegions(this.preferredRegion) || [],
        preferredCountry: this.listUnSelectedPreferredCountry || [],
        currently: [
          { id: false, qualification: 'qualified', label: "Qualified/Registered Healthcare Professional/Worker" },
          { id: true, qualification: 'student_qualified', label: "Student in Training for a Healthcare Profession (i.e. Unqualified)" },
          { id: 0, qualification: 'non_healthcare', label: "Non-Clinical Roles (i.e. Non-Health and not listed elsewhere)" },
        ],
        working: [
          { id: 1, label: "Full Time" },
          { id: 2, label: "Part Time" },
        ],
        modeOfCourse: [
          { label_code: 1, label: "Full Time" },
          { label_code: 2, label: "Part Time" }
        ],
        student_qualifications: this.studentQualification || [],
        sponsers_list: this.sponsorsList || [],
        course_test_type: this.getCourseTestTypes.map((item) => ({
          code: item.course_test_type_id,
          label: item.course_test_type
        })) || [],
        course_test_type_vocational: this.getCourseTestTypes
          .map(item => ({
            code: item.course_test_type_id,
            label: item.course_test_type
          })).filter(v => v.code === 1) || [],
        languageList: this.locationLanguageReOrder || [],
        languageExam: this.getLanguageTest || [],
        studentCourseSpeciality: this.getCourseSpeciality || [],
        course_qualifications: this.getCourseQualifications || [],
        isPrivate: [
          { id: true, label: "Make my profile Private" },
          { id: false, label: "Make my profile Public" },
        ],
        distance_online: [
          { code: true, label: "Distance" },
          { code: false, label: "Online" },
          { code: null, label: "N/A" },
        ],
        course_list: this.getCourseTitles || [],

        languageListOptions: [
          { code: 1, label: "Already Obtained" },
          { code: 2, label: "Currently Studying" },
        ]
      };
    },
    customSortQualification() {
      return this.qualByCtryAndCanTypeWithoutOther.sort(
        (a, b) => a.display_order - b.display_order
      );
    },
    fileType() {
      if (this.candidate.document_type && this.candidate.document_type.code) {
        let fileext = this.documentTypeExcPhoto
          .filter(({ code }) => code === this.candidate.document_type.code)
          .map((d) => d.allowed_extensions)[0];
        fileext = fileext.map((d) => d.toLowerCase());
        return this.arraytoComma(fileext);
      } else {
        let fileexts = this.documentTypeExcPhoto
          .filter(({ code }) => code === 1)
          .map((d) => d.allowed_extensions);
        let fileext = fileexts.length ? fileexts[0] : [];
        fileext = fileext.map((d) => d.toLowerCase());
        return this.arraytoComma(fileext);
      }
    },
    isNurse() {
      return this.candidate?.candidate_type_id?.code === 2 || false;
    },
    isAHP() {
      return this.candidate?.candidate_type_id?.code === 3 || false;
    },
    isPhysician() {
      return this.candidate?.candidate_type_id?.code === 1 || false;
    },
    isDentist() {
      return this.candidate?.candidate_type_id?.code === 4 || false;
    },
    isNonClinicAdmin() {
      return this.candidate?.candidate_type_id?.code === 6 || false;
    },
    isSocialCareWorker() {
      return this.candidate?.candidate_type_id?.code === 5 || false;
    },
    nurseTypes() {
      return (
        this.nurseRegistrationType.map((val) => ({
          code: val,
          label: this.camelize(val),
        })) || []
      );
    },
    firstQualificationIsOther() {
      return (
        this.candidate?.first_qualification_id?.label.includes(
          "Other - not listed"
        ) || false
      );
    },
    mainQualificationIsOther() {
      return (
        this.candidate?.main_qualification_id?.label.includes(
          "Other - not listed"
        ) || false
      );
    },
    specialityIsOther() {
      return (
        this.candidate?.speciality_id?.label.includes("Other - not listed") ||
        false
      );
    },
    isOther() {
      return (
        (this.candidate?.other_areas_of_expertise_ids &&
          this.candidate?.other_areas_of_expertise_ids.some(
            ({ label }) => label === "Other - not listed"
          )) ||
        false
      );
    },
    hospitalName() {
      return getLocalOrgDetail()?.name ? getLocalOrgDetail()?.name : "";
    },
    isParent() {
      return getLocalOrgDetail()?.is_parent || false;
    },
    isStudent() {
      return this.payload.is_student || false;
    },
    isStudentLevel() {
      return [38, 131].includes(this.candidate?.level_id?.code);
    },
    isUploading() {
      return this.uploadResponse !== 0;
    },
    preferredRegion() {
      return this.locationRegion
        ? this.locationRegion.filter((val) => {
          return (
            val.job_only != true &&
            !this.candidate["region_ids"]?.some(
              ({ code }) => code === val.code
            )
          );
        })
        : [] || [];
    },
    preferredCountry() {
      let regions = this.candidate?.region_ids?.map((e) => {
        return e.code;
      });
      return this.candidate?.region_ids
        ? this.PreferredCountryFilterByRegion({ regions }).sort((a, b) =>
          a.label === "United Arab Emirates" ? -1 : 1
        )
        : [];
    },
    listUnSelectedPreferredCountry() {
      return this.candidate?.region_ids
        ? this.preferredCountry.filter(
          (val) =>
            !this.candidate["preferred_country_ids"]?.some(
              ({ code }) => code === val.code
            )
        )
        : [];
    },
    isEducationalInstitute() {
      return this.getOrgDetail.customer_type_id === CONST.CUSTOMER_TYPES.educational_institute;
    },
    isLanguageSchool() {
      return this.getOrgDetail.customer_type_id === CONST.CUSTOMER_TYPES.language_school;
    },
    studentQualification() {
      return this.getStudentQualifications.map((item) => ({
        code: item.qualification_id,
        label: item.qualification
      }));
    },
    isStudying() {
      return this.payload.current_qualification === 2
    },
    sponsorsList() {
      return this.getSponsorsList.map((item) => ({
        code: item.sponsor_deal_id,
        label: item.deal_name
      }));
    },
    isQualified() {
      return this.candidate?.is_student?.qualification === 'qualified';
    },
    isStudentQualified() {
      return this.candidate?.is_student?.qualification === 'student_qualified' || this.isStudent;
    },
    isNonHealthcare() {
      return this.candidate?.is_student?.qualification === 'non_healthcare';
    },
    candidateTypes() {
      return this.candidateType.filter(item =>
        this.candidate?.is_student === 0 ? item.code === 6 : item.code !== 6);
    },
    isAdditionalCourse() {
      return this.candidate?.is_additional_course === true;
    },
    speciality() {
      let specialities = this.specialityByTypeAndCountry({
        candidateType: this.payload.candidate_type_id,
        country_id: this.payload.main_qualification_country_id,
      }) || [];
      if (this.isStudentQualified) {
        let courseId = this.isPhysician ? 403 : (this.isDentist ? 137 : null);
        if (courseId !== null) {
          return this.handleChangeSelect('course_speciality_id', courseId);
        }
      }
      return specialities
    },
    getLanguageTest() {
      const mappedArray = this.getLanguageExam.map((val) => ({
        code: val?.test_id,
        label: val?.test_acronym && val?.test_name
          ? `${val.test_acronym}-${val.test_name}`
          : val?.test_name,
      }));
      const sortedArray = mappedArray.sort((a, b) => a.label.localeCompare(b.label));
      return sortedArray;
    },
    locationLanguageReOrder() {
      return (
        this.locationLanguage &&
        this.locationLanguage.length &&
        this.locationLanguage.sort((a, b) => {
          return a.code === 1 ? -1 : 1;
        })
      );
    },
    getCourseTitles() {
      return this.getCourseTitleList
          .filter(v => v.organisation_id === this.getOrgDetail?.organisation_id  
                    && v.candidate_type_ids?.includes(this.payload?.candidate_type_id))
          .map(course => ({
              code: course.training_course_id,
              label: course.course_title
          }));
    },
    filterCurrentlyOptions() {
      if (this.isEducationalInstitute) {
        return this.options.currently.filter(option => option.id !== 0);
      } else {
        return this.options.currently;
      }
    }, 
    getOrgId() {
      if(this.organisationID) {
        return this.organisationID
      } else if(this.getOrgIDFromAccessToken) {
        return this.getOrgIDFromAccessToken
      }
      return null
    },
    isFullRegister() {
      return this.$route.query['full-register'] == 'true' || false;
    }
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        this.url = this.profilePhoto.value.croppedImageSrc;
        this.$refs.profilefileref.value = "";
        this.isImageSelected = false;
        this.fileData = {};
      }
    },
    payload() {
      this.options["subSpeciality"].length <= 0
        ? this.changeFieldValues("sub_speciality_id", null)
        : null;
      this.payload.main_qualification_speciality_id = this.payload.speciality_id
        ? this.payload.speciality_id
        : null;
    },
    "payload.first_qualification_country_id"() {
      this.changeFieldValues("first_qualification_id", null);
      if (this.payload?.first_qualification_country_id) {
        let first_qual_country = _.filter(
          this.options["locationInfo"],
          (option) => {
            return this.payload?.first_qualification_country_id == option?.code;
          }
        );
        this.candidate.main_qualification_country_id = first_qual_country[0];
        this.payload.main_qualification_country_id =
          first_qual_country[0]?.code;
        // this.fetchHigherQualificationByCountryAndCandidateType({
        //   country_id: this.payload.main_qualification_country_id,
        //   candidate_type_id: this.payload.candidate_type_id,
        // });
      }
    },
    "payload.main_qualification_country_id"() {
      this.changeFieldValues("main_qualification_id", null);
    },
    "payload.candidate_type_id"() {
      this.changeFieldValues("main_qualification_country_id", null);
      if (this.isStudentQualified) {
        this.fetchCourse(this.payload.candidate_type_id)
      }
      if (this.isStudentQualified && (this.isNurse || this.isAHP)) {
        this.fetchSpeciality(this.payload.candidate_type_id)
      }
    },
    "candidate.main_qualification_id"() {
      this.candidate.main_qualification_id?.specialist_registration_reqd ==
        false
        ? this.changeFieldValues("specialist_registration", null)
        : null;
    },
    "payload.sub_speciality_id"() {
      this.payload.main_qualification_sub_speciality_id =
        this.payload.sub_speciality_id;
    },

    "payload.specialist_registration"() {
      this.payload.specialist_registration
        ? null
        : this.changeFieldValues("date_acquired", null);
    },
    "candidate.isRegisteredWhatsapp"() {
      this.candidate.isRegisteredWhatsapp
        ? ((this.candidate.whatsapp = this.candidate.phone),
          (this.payload.whatsapp = this.payload.phone))
        : this.changeFieldValues("whatsapp", null);
    },
    "candidate.preferred_contact_method"() {
      this.candidate.preferred_contact_method.some((el) => el.id == "whatsapp")
        ? (this.candidate.isRegisteredWhatsapp = true)
        : null;
    },
    isCandidateSupplier() {
      this.displayPage = 4;
    },
    getFilteredIssueAuthority() {
      if (this.getFilteredIssueAuthority?.length) {
        this.candidate.main_qualification_issue_authority_id =
          this.options["issued_by"][0];
        this.payload.main_qualification_issue_authority_id =
          this.options["issued_by"][0].code;
      } else {
        this.payload.main_qualification_issue_authority_id =
          this.candidate.main_qualification_issue_authority_id = null;
      }
    },
    preferredCountry() {
      this.checkOptionsAndValuesForMultiSelect(
        "preferred_country_ids",
        "preferredCountry"
      );
    },
  },
  mounted() {
    this.scrollTop();
    this.initQuickRegisterFetchOptions();
    this.fetchIssueAuthorityByQualification();
    this.fetchQualificationByCountryAndCandidateType();
    this.fetchDepartments({ organisation_id: this.organisationID });
    let isMobile = window.matchMedia(
      "only screen and (max-width: 400px)"
    ).matches;
    this.isMobile = isMobile;
    this.handleInput("alternate_email", this.existingCandidate.email);
    this.handleInput("email", this.existingCandidate.email);
    this.payload["organisation_id"] = this.getOrgId
    this.locationID ? (this.payload["location_id"] = this.locationID) : null;
    this.fetchNurseRegistrationType({ customer_id: this.customer });
    
    let check_email = (this.$route.query?.check_email || '').trim()
    check_email = decodeURIComponent(check_email).replace(/\s/g, '+');;

    if (check_email) {
      this.handleExistingCandidateInput("email", check_email);
      this.checkCandidateExists();
    }
    this.handleChangeSelect("course_name", {code:1, label: "Language"})

    if (this.campaignData) {
      // this.campaignModal.isShowPopup = this.campaignData?.is_campaign || false;
      const { first_name, sur_name, phone } = this.campaignData;
      this.handleInput("first_name", first_name);
      this.handleInput("surname", sur_name);
      this.handleInput("phone", phone);
    }

    if (this.campaignFieldsData.length) {
      this.payload.is_student = true;
      this.campaignFieldsData.forEach((field) => {
        if (field.campaign_field_id == 1) {
          this.handleInput("first_name", field.text_area_response);
        }
        if (field.campaign_field_id == 2) {
          this.handleInput("surname", field.text_area_response);
        }
        if (field.campaign_field_id == 3 && this.existingCandidate.mail == field.text_area_response) {
          this.handleEmailCheck("alternate_email", field.text_area_response);
        }
        if (field.campaign_field_id == 4) {
          this.handleInput("phone", field.text_area_response);
        }
        if (field.campaign_field_id == 28 && field.options_response[0]?.includes('A fully qualified healthcare professional')) {
          this.candidate.is_student = { id: false, label: "A fully qualified healthcare professional (post-graduate)" }
          this.payload.is_student = false;
        }
        else if (field.campaign_field_id == 28 && field.options_response[0]?.includes('A student studying a healthcare profession (undergraduate)')) {
          this.candidate.is_student = { id: true, label: "A student studying a healthcare profession (undergraduate)" }
          this.payload.is_student = true;
          this.ahpStudentTrainee = true;
        }
        else if (field.campaign_field_id == 28) {
          this.candidate.is_student = { id: null, label: "Non Healthcare" }
          this.payload.is_student = true;
        }
      });
    };
    this.fetchCourseTestTypes();
    this.fetchLocationLanguage();
    this.fetchLanguageExam();
    this.fetchCourseTitleList();
  },
  methods: {
    ...mapActions([
      "scrollTop",
      "initQuickRegisterFetchOptions",
      "showToast",
      "multipleFileUpload",
      "fetchQualificationByCandidateType",
      "fetchQualificationByCountryAndCandidateType",
      "fetchIssueAuthorityByQualification",
      "fetchDepartments",
      "fetchQualificationByCountryAndCandidateTypeWithoutGetter",
      "fetchFirstQualificationByCountryAndCandidateType",
      "fetchHigherQualificationByCountryAndCandidateType",
      "fetchNurseRegistrationType",
      "fetchIssueAuthorityByParams",
      "getRegisteredHospitalsByEmail",
      "registerCommonCandidate",
      "uploadTempDoc",
      "getTempDocs",
      "resetCandidate",
      "fetchUser",

      "createCandidate",
      "uploadPhoto",
      "multipleFileUpload",
      "deleteTempDocs",
      "resetCandidate",
      "clearCampaignFieldsData",
      "fetchStudentQualifications",
      "fetchSponsorsList",
      "fetchCourseTestTypes",
      "fetchLocationLanguage",
      "fetchLanguageExam",
      "fetchCourseSpeciality",
      "fetchCourseQualification",
      "fetchCourseTitleList",
      "updateCampaignPreRegCompleted",
      "isEmailBelongsToAcronym",
      "login"
    ]),
    smallCaseConvertion(name, value) {
      const val = value.toLowerCase();
      Vue.set(this.candidate, name, val);
      Vue.set(this.payload, name, val);
    },
    handleInput(name, value) {
      Vue.set(this.candidate, name, value);
      Vue.set(this.payload, name, value);
      let hasError = {
        phone: "phone",
        whatsapp: "whatsapp",
      };
      if ((name === hasError.phone || hasError.whatsapp) && value === "") {
        this.clearFieldErrors(name, hasError);
      }
      let email_name = "email";
      if (this.isCandidateSupplier && name === "alternate_email") {
        Vue.set(this.candidate, email_name, value);
        Vue.set(this.payload, email_name, value);
      }
    },
    handleEmailCheck(name, value) {
      Vue.set(this.candidate, name, value);
      Vue.set(this.payload, name, value);
      const email_name = "email";
      if (!this.isCandidateSupplier) {
        if (
          name === "alternate_email" &&
          this.existingCandidate.email != value
        ) {
          Vue.set(this.candidate, email_name, null);
          Vue.set(this.payload, email_name, null);
          this.emailModal.isShowPopup = true;
          this.emailModal.modalContent = `Entered email id does not match with Sign Up email(${this.existingCandidate.email})! Please re-enter the correct email !`;
        } else {
          Vue.set(this.candidate, email_name, value);
          Vue.set(this.payload, email_name, value);
        }
      } else {
        Vue.set(this.candidate, email_name, value);
        Vue.set(this.payload, email_name, value);
      }
    },
    emailmodalCallBack() {
      this.emailModal.isShowPopup = false;
      this.handleInput("alternate_email", this.existingCandidate.email);
      this.handleInput("email", this.existingCandidate.email);
      this.$refs.alternate_email.applyResult({
        errors: [
          "Entered email id does not match with Sign Up email! Please re-enter the correct email !",
        ],
        valid: false,
        failedRules: {},
      });
    },
    changeFieldValues(name, value) {
      Vue.set(this.candidate, name, value);
      Vue.set(this.payload, name, value);
    },
    handleEmailChange(name, value) {
      if (
        !this.checkEmptyString(this.candidate["alternate_email"]) &&
        this.candidate["email"] != this.candidate["alternate_email"]
      ) {
        this.emailConfirmModal.isShowPopup = true;
        Vue.set(this.candidate, "share_profile", false);
        Vue.set(this.payload, "share_profile", false);
      }
    },
    checkEmptyString(str) {
      if (str != null) return str.trim() === "";
      return true;
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.candidate, name, value);
      if (name == "qualified_date") {
        this.payload.main_qualification_month_awarded = value
          ? m(value).month() + 1
          : null;
        this.payload.main_qualification_year_awarded = value
          ? m(value).year()
          : null;
        return;
      }
      if (name == "first_qualified_date") {
        this.payload.first_qualification_month_awarded = value
          ? m(value).month() + 1
          : null;
        this.payload.first_qualification_year_awarded = value
          ? m(value).year()
          : null;
        return;
      }
      Vue.set(this.payload, name, m(value).format("YYYY-MM-DD"));
    },
    handleChangeSelect(name, value) {
      if (
        name === "speciality_id" &&
        this.payload?.other_areas_of_expertise_ids &&
        this.payload?.other_areas_of_expertise_ids.some(
          (v) =>
            v?.speciality_id === value?.code &&
            v?.sub_speciality_id === value?.sub_speciality_id
        )
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message:
            "Speciality already exists! Please select another Speciality",
        });
        return;
      }
      if (["first_qualification_id", "main_qualification_id"].includes(name)) {
        const checkingField = {
          first_qualification_id: "main_qualification_id",
          main_qualification_id: "first_qualification_id",
        };
        const field = checkingField[name];
        this.payload.nurse_registration_type
          ? delete this.payload.nurse_registration_type
          : null;
        this.candidate.any_higherqualification_related_to_practice = null;
        this.candidate.speciality_nurse_or_nurse_practitioner = null;
        if (this.payload[field] && this.payload[field] === value?.code) {
          this.showToast({
            class: "bg-danger text-white",
            message: "Qualification Already Exists",
          });
          return;
        }
      }
      if (name == "candidate_type_id") {
        const professionFields = [
          "main_qualification_id",
          "other_qualification_name",
          "main_qualification_year_awarded",
          "main_qualification_month_awarded",
          "main_qualification_country_id",
          "main_qualification_course_duration",
          "main_qualification_description",
          "main_qualification_by_exam",
          "main_qualification_other_issue_authority",
          "main_qualification_issue_authority_id",
          "speciality_id",
          "first_qualified_date",
          "qualified_date",
          "sub_speciality_id",
          "speciality_duration",
          "first_other_qualification_name",
          "first_qualification_id",
          "first_qualification_year_awarded",
          "first_qualification_month_awarded",
          "first_qualification_country_id",
          "first_qualification_distance_learning",
          "first_qualification_issue_authority_id",
          "first_qualification_issue_authority",
          "first_qualification_course_duration",
          "first_qualification_description",
          "first_qualification_by_exam",
          "level_id",
          "acquired_date",
          "specialist_registration",
        ];
        _.forEach(professionFields, (field) => {
          this.changeFieldValues(field, null);
        });
        this.changeFieldValues("is_have_higher_qualification", false);
        this.fetchQualificationByCountryAndCandidateType();
        // this.fetchFirstQualificationByCountryAndCandidateType();
        // this.fetchHigherQualificationByCountryAndCandidateType();
      }
      Vue.set(this.candidate, name, value);
      let document_type = "document_type";
      let preferred_contact_method = "preferred_contact_method";
      if (name == preferred_contact_method) {
        let temp = "";
        value.forEach((ele) => {
          temp = temp ? temp + ", " + ele.id : ele.id;
        });
        value = temp;
      }
      if (name != document_type) {
        this.payload = {
          ...this.payload,
          [name]: value ? value.id || value.code || value : null,
        };
      }
      let hasError = {
        month_awarded: "main_qualification_month_awarded",
      };
      if (name == hasError.month_awarded && !value) {
        this.clearFieldErrors(name, hasError);
      }
      let mainQualification = "main_qualification_id";
      if (name === mainQualification) {
        this.handleChangeRadio(
          "main_qualification_by_exam",
          value?.by_exam ? true : null
        );
        this.fetchIssueAuthorityByQualification({
          qualification_id: this.payload.main_qualification_id,
        });
      }
      if (
        name == "candidate_type_id" ||
        name == "main_qualification_country_id"
      ) {
        if (name == "main_qualification_country_id" && this.isPhysician) {
          this.fetchHigherQualificationByCountryAndCandidateType({
            country_id: this.payload.main_qualification_country_id,
            candidate_type_id: this.payload.candidate_type_id,
          });
        } else if (
          name == "candidate_type_id" ||
          (this.payload.main_qualification_country_id &&
            this.payload.candidate_type_id)
        ) {
          this.fetchQualificationByCountryAndCandidateType({
            country_id: this.payload.main_qualification_country_id,
            candidate_type_id: this.payload.candidate_type_id,
          });
        } else if (
          name == "candidate_type_id" &&
          this.isNonClinicAdmin &&
          this.payload.candidate_type_id
        ) {
          this.fetchQualificationByCandidateType({
            candidate_type_id: this.payload.candidate_type_id,
          });
        }
      }
      if (
        name == "candidate_type_id" ||
        name == "first_qualification_country_id"
      ) {
        if (
          name === "first_qualification_country_id" &&
          (this.isNurse || this.isAHP)
        ) {
          this.fetchQualificationByCountryAndCandidateType({
            country_id: this.payload.first_qualification_country_id,
            candidate_type_id: this.payload.candidate_type_id,
          });
        } else if (
          (name == "candidate_type_id" && !this.isNurse) ||
          (!this.isAHP &&
            this.payload.first_qualification_country_id &&
            this.payload.candidate_type_id)
        ) {
          this.fetchQualificationByCountryAndCandidateType({
            country_id: this.payload.first_qualification_country_id,
            candidate_type_id: this.payload.candidate_type_id,
          });
        }
      }
      if (name === "speciality_id" && this.isNurse) {
        this.handleChangeSelect("sub_speciality_id", {
          code: value?.sub_speciality_id,
          label: value?.sub_speciality,
        });
      }
      if (name === "course_part_time") {
        this.payload.course_part_time = value.label;
      }
      if (name == "main_qualification_country_id") {
        let payload = {
          candidate_type_id: this.payload?.candidate_type_id,
          country_id: this.payload?.main_qualification_country_id,
          is_qualification: true
        }
        this.fetchIssueAuthorityByParams(payload)
      }
    },
    handleChangeMultiSelectAsArray(name, value) {
      if (
        name === "other_areas_of_expertise_ids" &&
        value &&
        value.some(
          ({ code, sub_speciality_id }) =>
            code === this.payload?.speciality_id &&
            (sub_speciality_id
              ? sub_speciality_id === this.payload?.sub_speciality_id
              : true)
        )
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message:
            "Speciality already exists! Please select another Speciality",
        });
        return;
      }
      Vue.set(this.candidate, name, value);
      if (name === "region_ids" || name === "preferred_country_ids") {
        let last_index = value[value.length - 1];
        let val = null;
        if (last_index?.label == "All" || !last_index) {
          Vue.set(this.candidate, name, [{ code: 0, label: "All" }]);
          this.payload = {
            ...this.payload,
            [name]: val,
          };
        } else {
          if (value[0].label == "All") value = value.slice(1);
          val = value.map((v) => {
            return v.id || v.code || value;
          });
          Vue.set(this.candidate, name, value);
          this.payload = {
            ...this.payload,
            [name]: val,
          };
        }
      }
      if (
        (this.isNurse || this.isNonClinicAdmin) &&
        name === "other_areas_of_expertise_ids"
      ) {
        this.payload = {
          ...this.payload,
          [name]: value
            ? value.map((v) => {
              return {
                speciality_id: v.code,
                sub_speciality_id: v.sub_speciality_id || null,
              };
            })
            : null,
        };
      } else {
        this.payload = {
          ...this.payload,
          [name]: value
            ? value.map((v) => {
              return v.id || v.code || v;
            })
            : null,
        };
      }
    },
    handleChangeCheck(name, $event) {
      const checked = $event.target.checked;
      Vue.set(this.candidate, name, checked);
    },
    goToLogin() {
      if (this.getOrgVariant == Variant.campaign) {
        this.resetCandidate();
        let { path_acronym, customer_uid, organisation_id, location_id } =
          getLocalOrgDetail();

        let path = `/${path_acronym}/register/${customer_uid}`;
        if (organisation_id)
          path += `/organisation/${organisation_id}/location/${location_id}`;

        this.$router.push({ path: path });
        this.displayPage = 1;
        this.resetForm();
      } else {
        this.$router.push({ path: LOGIN_URL() });
      }
    },
    setCurrentData(name, value) {
      Vue.set(this.candidate, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeRadio(name, value) {
      this.notifyModal.name = name
      let val = null;
      if (value?.id === false || value?.code === false) {
        val = false;
      }else if (value?.id === 0) {
        val = 0
      }
      
      else {
        val = value?.id || value?.code || value;
      }
      Vue.set(this.candidate, name, val);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
      if (name === "is_student") {
        this.payload.is_student = value?.id == false ? false : true;
        this.ahpStudentTrainee = this.payload.is_student;
        if (this.isNotify) {
        this.notifyModal.isShowPopup = true;
        this.isNotify =false
      }
      this.notifyModal.newVal = val; 
      return;   
      }
      if (name === "any_higherqualification_related_to_practice" && !val) {
        this.handleChangeRadio("speciality_nurse_or_nurse_practitioner", null);
      }
      if (name === 'is_sponsored' && this.payload.is_sponsored) {
        this.fetchSponsorsList()
      }
      if (name === "student_course_mode") {
        this.payload.student_course_mode = val.label
      }
      if (name === "is_additional_course") {
         this.isNotify = true
         this.notifyModal.newVal = val;
         return 
     }
    },
    handleFile(event) {
      let docType = this.candidate.document_type;
      if (!docType) {
        let resume_docType = this.documentTypeExcPhoto.filter(
          ({ code }) => code === 1
        );
        docType = resume_docType.length ? resume_docType[0] : [];
        let document_type = "document_type";
        Vue.set(this.candidate, document_type, docType);
      }
      let { name: filename } = event.target.files[0];
      let fileExt = filename.split(".").pop();
      const fileTypeString = this.fileType;
      let versionControl = {
        // resume: "CV / Resume",
        coverLetter: "Cover Letter",
        surgicalLogbook: "Surgical Logbook",
      };
      let versionFound = false;
      if (this.documentExists(docType, filename)) {
        this.$refs.docfileref.value = "";
        this.showToast({
          class: "bg-danger text-white",
          message: "Document already exists! Please select another Document",
        });
      } else if (!fileTypeString.includes(fileExt)) {
        this.candidate.document_type = "";
        this.$refs.docfileref.value = "";
        this.showToast({
          class: "bg-danger text-white",
          message: `Allowed File Type ${fileTypeString}`,
        });
      } else {
        if (
          // docType.label === versionControl.resume ||
          docType.label === versionControl.coverLetter ||
          docType.label === versionControl.surgicalLogbook
        ) {
          versionFound = this.uploadFiles.some(
            (el) => el.docType.code === docType.code
          );
        }
        this.fileUpload(docType, event, versionFound);
      }
    },
    fileUpload(docType, event, versionFound) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        this.isFileError = false;
        let ext = event.target.files[0]?.name?.split(".").pop().toUpperCase();
        let fileData = {
          docType,
          file: event.target.files[0],
          fileExt: ext,
          fileName: `${docType?.code}_${event.target.files[0].name}`,
        };
        if (["DOC", "DOCX", "XLS", "XLSX"].includes(ext)) {
          this.uploadTempDoc({ uuid: this.randomUuid, ...fileData }).then(
            (res) => {
              if (res && res.length) {
                let isTemp = true;
                fileData = { ...fileData, isTemp };
                this.insertDoc({ fileData, docType, event, versionFound });
              }
            }
          );
        } else {
          this.insertDoc({ fileData, docType, event, versionFound });
        }
        this.$refs.docfileref.value = "";
        this.candidate.document_type = "";
      } else {
        this.$refs.docfileref.value = "";
        this.isFileError = true;
      }
    },
    insertDoc({ fileData, docType, event, versionFound }) {
      if (versionFound) {
        for (let i = 0; i < this.uploadFiles.length; i++) {
          if (this.uploadFiles[i].docType.code === docType.code) {
            this.$nextTick(() => {
              Vue.set(this.uploadFiles, i, fileData);
            });
          }
        }
      } else {
        this.$nextTick(() => {
          let data = { ...fileData };
          const { docType } = fileData;
          const document_type_id = docType?.code;
          if (document_type_id === 1) {
            data["isLatest"] = true;
            let uploadFiles = this.uploadFiles;
            uploadFiles.map((v) => {
              const {
                docType: { code },
              } = v;
              let val = v;
              if (code === 1) val["isLatest"] = false;
              return val;
            });
            this.uploadFiles = [...uploadFiles];
          }
          this.uploadFiles.push(data);
        });
      }
    },
    handleConfirmPassword(name, value) {
      this[name] = value;
    },
    resetForm() {
      this.fileData = {};
      this.candidate = {
        preferred_contact_method: [],
        isRegisteredWhatsapp: false,
        is_have_higher_qualification: false,
        share_profile: false,
      };
      let email = this.existingCandidate["email"];
      Vue.set(this.candidate, "email", email);
      Vue.set(this.candidate, "alternate_email", email);
      Vue.set(this.payload, "email", email);
      Vue.set(this.payload, "alternate_email", email);
      this.existingCandidate = { email };
      this.confirmPassword = null;
      this.$refs.phoneNo?.resetPhoneno();
      this.$refs.whatsappNo?.resetPhoneno();
    },
    async onSubmit() {
      const isValid = await this.$refs.register.validate();
      const errors = Object.values(this.$refs.register.errors).flat();
      if (this.isNurse || this.isAHP) {
        this.payload.first_qualification_country_id =
          this.candidate?.first_qualification_country_id?.code;
        this.payload.main_qualification_country_id =
          this.candidate?.main_qualification_country_id?.code;
        this.payload.main_qualification_course_duration =
          this.candidate.main_qualification_course_duration;
        this.payload.first_qualification_course_duration =
          this.candidate.first_qualification_course_duration;
        this.payload.speciality_duration = this.candidate.speciality_duration;
      }
      this.payload.years_at_level = this.candidate.years_at_level;
      if (this.isCandidateSupplier && this.getUserContactId && this.getUser) {
        this.payload.customer_id = this.customer || getLocalOrgDetail()?.customer_uid || null;
        (this.payload.contact_id = this.getUserContactId),
          (this.payload.created_by = this.getUser?.id
            ? this.getUser?.id
            : null);
      }
      if (!isValid) {
        let el = this.$el.getElementsByClassName("has-error")[0];
        el?.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.showToast({
          class: "bg-danger text-white",
          message: errors[0] || "please fill mandatory fields!",
        });
        return;
      } else if (
        !this.isNurse &&
        !this.isAHP &&
        this.candidate?.main_qualification_id?.course_duration_reqd &&
        !this.payload.main_qualification_course_duration &&
        !this.isStudent &&
        !this.isSocialCareWorker
      ) {
        let el = this.$el.getElementsByClassName("course_duration")[0];
        el?.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.showToast({
          class: "bg-danger text-white",
          message: "Qualification Course Duration can't be less than one year!",
        });
      }
      else if (
        (this.isAHP || this.isNurse) &&
        !this.payload.speciality_duration && !this.isStudent &&
        !this.isStudentLevel
      ) {
        let el = this.$el.getElementsByClassName("speciality_duration")[0];
        el?.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.showToast({
          class: "bg-danger text-white",
          message: "Years in speciality cannot be less than one year!",
        });
      }
      else if (
        !this.payload.first_qualification_course_duration &&
        this.isNurse &&
        !this.isStudent
      ) {
        let el = this.$el.getElementsByClassName("first_course_duration")[0];
        el?.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.showToast({
          class: "bg-danger text-white",
          message: "Qualification Course Duration can't be less than one year!",
        });
      } else if (
        !this.payload.main_qualification_course_duration &&
        this.isAHP &&
        !this.isStudent
      ) {
        let el = this.$el.getElementsByClassName("first_course_duration")[0];
        el?.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.showToast({
          class: "bg-danger text-white",
          message: "Qualification Course Duration can't be less than one year!",
        });
      } else if (
        (this.isNurse || this.isAHP) &&
        this.payload.is_have_higher_qualification &&
        !this.payload.main_qualification_course_duration &&
        !this.isStudent
      ) {
        let el = this.$el.getElementsByClassName("course_duration")[0];
        el?.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.showToast({
          class: "bg-danger text-white",
          message: "Qualification Course Duration can't be less than one year!",
        });
      } else if (
        (this.isNurse || this.isAHP) &&
        this.payload?.is_have_higher_qualification &&
        this.payload?.first_qualification_id ===
        this.payload?.main_qualification_id
      ) {
        let el = this.$el.getElementsByClassName("main_qualification")[0];
        el?.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.showToast({
          class: "bg-danger text-white",
          message: "First and Main Qualifications are Same!",
        });
      } else if (this.isTermPrivacyChecked) {
        !this.payload.sub_speciality_id
          ? delete this.payload.sub_speciality_id
          : null;
        this.payload.main_qualification_issue_authority_id === "Others"
          ? (this.payload.main_qualification_issue_authority_id = null)
          : null;
        this.payload.main_qualification_issue_authority_id === "Others"
          ? delete this.payload.main_qualification_other_issue_authority
          : null;
        this.candidate.isRegisteredWhatsapp
          ? (this.payload.whatsapp = this.payload.phone)
          : null;
        this.candidate.main_qualification_id?.label?.includes(
          "Other - not listed"
        )
          ? null
          : delete this.payload.other_qualification_name;
        this.payload = {
          ...this.payload,
          privacy_acceptance: this.isTermPrivacyChecked,
        };
        this.payload.first_qualification_issue_authority_id === "Others"
          ? (this.payload.first_qualification_issue_authority_id = null)
          : null;

        if (
          this.payload?.speciality_id?.label?.includes("Other - not listed")
        ) {
          this.payload.speciality_id = null;
          this.payload.main_qualification_speciality_id = null;
        } else {
          this.payload.other_speciality = null;
        }
        if (
          this.candidate?.other_areas_of_expertise_ids &&
          this.candidate?.other_areas_of_expertise_ids.some(
            ({ label }) => label === "Other - not listed"
          )
        ) {
          this.payload.other_areas_of_expertise_ids =
            this.candidate.other_areas_of_expertise_ids.map((v) => {
              return v.label === "Other - not listed"
                ? {
                  id: v.code || null,
                  other_name: this.payload?.other_areas_of_expertise_name,
                }
                : v.code;
            });
        }
        this.payload.other_areas_of_expertise_name
          ? delete this.payload.other_areas_of_expertise_name
          : null;
        let finalPayload = {
          ...this.payload,
          is_private: this.payload?.is_private || false
        };
        const deleteNurseFields = [
          "first_other_qualification_name",
          "first_qualification_id",
          "first_qualification_year_awarded",
          "first_qualification_month_awarded",
          "first_qualification_country_id",
          "first_qualification_distance_learning",
          "first_qualification_issue_authority_id",
          "first_qualification_issue_authority",
          "first_qualification_course_duration",
          "first_qualification_description",
          "first_qualification_by_exam",
        ];
        const deletequlaifications = [
          "main_qualification_id",
          "main_qualification_year_awarded",
          "main_qualification_month_awarded",
          "main_qualification_country_id",
          "main_qualification_course_duration",
          "main_qualification_description",
          "main_qualification_by_exam",
          "main_qualification_other_issue_authority",
        ]
        if (!this.isNurse && !this.isPhysician) {
          this.deleteFields(deleteNurseFields);
          this.deleteFields(["level_id"]);
        } else if (this.isAHP) {
          this.deleteFields(deleteNurseFields);
          this.deleteFields(["level_id"]);
        } else if (!this.isNurse && !this.isNonClinicAdmin) {
          this.deleteFields(["other_areas_of_expertise_ids"]);
        }
        if (this.isStudent) {
          let deletefields = deleteNurseFields.concat(deletequlaifications)
          this.deleteFields(deletefields);
        }
        if (this.isNurse) {
          if (!this.payload.is_have_higher_qualification) {
            const temp = {
              main_qualification_id: this.payload?.first_qualification_id,
              main_qualification_year_awarded:
                this.payload?.first_qualification_year_awarded,
              main_qualification_month_awarded:
                this.payload?.first_qualification_month_awarded,
              main_qualification_country_id:
                this.payload?.first_qualification_country_id,
              main_qualification_course_duration:
                this.payload?.first_qualification_course_duration,
              main_qualification_description:
                this.payload?.first_qualification_description,
              main_qualification_by_exam:
                this.payload?.first_qualification_by_exam,
              other_qualification_name:
                this.payload?.first_other_qualification_name,
            };
            finalPayload = { ...finalPayload, ...temp };
            deleteNurseFields.forEach((field) => {
              delete finalPayload[field];
            });
          }
          if (this.isNurse) {
            let nurse_registration_type = [];
            this.nurseRegistrationType.forEach((e) => {
              finalPayload[e] === true ? nurse_registration_type.push(e) : null;
              delete finalPayload[e];
            });
            finalPayload["nurse_registration_type"] =
              nurse_registration_type.length ? nurse_registration_type : null;
          }
        }
        if (
          (this.isNurse &&
            this.payload.first_qualification_country_id != 182) ||
          !this.isNurse
        ) {
          finalPayload?.band_id ? delete finalPayload.band_id : null;
        }
        if (this.isPhysician) {
          finalPayload = {
            ...finalPayload,
            first_qualification_id: 984,
            first_qualification_country_id: this.payload?.nationality_ids[0],
          };
        }
        if (this.payload.is_sponsored) {
          let sponsored = {
            start_date: this.payload?.sponsorship_start_date,
            end_date: this.payload?.sponsorship_end_date,
          };
          finalPayload = {
            ...finalPayload,
            sponsored
          };
        }
        if(this.payload.is_additional_course) {
          let additional_language_qualification = {
            language_id: this.payload?.language_id,
            language_exam: this.payload?.language_exam,
            language_qualification: true,
            course_name: this.payload?.course_name,
          }
          finalPayload = {
            ...finalPayload,
            additional_language_qualification
          };
        }
        if(this.payload.is_additional_training_sponsored) {

          let additional_sponsored = {
            start_date: this.payload.additional_sponsorship_start_date,
            end_date: this.payload.additional_sponsorship_end_date
          }
          finalPayload = {
            ...finalPayload,
            additional_sponsored
          };
        }
        if(!this.payload.is_additional_course) {
          const deleteCourseFields = [
            'course_name',
            'language_id',
            'language_exam',
            'training_course_start_date',
            'training_course_end_date',
            'is_additional_training_sponsored',
            'additional_sponsorship_start_date',
            'additional_sponsorship_end_date'
          ]
          deleteCourseFields.forEach((field) => {
            delete finalPayload[field];
          });
        }
        let payload_data = {
          payload: finalPayload,
          docFiles: this.uploadFiles,
        };
        this.create(payload_data);
      } else {
        this.termConfirmModal.isShowPopup = true;
      }
    },
    selectedFile(event) {
      const file = event.target.files[0];
      this.isImageSelected = true;
      this.fileData = file;
    },
    closeModal() {
      this.$refs.profilefileref.value = "";
      this.fileData = {};
      this.isImageSelected = false;
    },
    arraytoComma(d) {
      return d?.map((v) => "." + v).join(", ");
    },
    documentExists(docType, filename) {
      let files = [];
      this.uploadFiles.forEach((element) => {
        if (element.docType.code === docType.code) {
          files.push(element);
        }
      });
      let fileFound = files.some((el) => el.file.name === filename);
      return fileFound ? true : false;
    },
    deleteDocuments(fileName, index) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this document - ${fileName} ?`;
      this.document_id = index;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deletemodalCallBack(action) {
      let confirmDelete = "Confirm";
      if (action === confirmDelete) {
        this.uploadFiles.splice(this.document_id, 1);
      }
      this.document_id = "";
      this.deleteConfirmModal.isShowPopup = false;
    },
    emailConfirmModalCallBack(action) {
      let confirmEmail = "Confirm";
      if (action === confirmEmail) {
        let alternet_email = "alternate_email";
        Vue.set(this.candidate, alternet_email, this.candidate["email"]);
        Vue.set(this.payload, alternet_email, this.candidate["email"]);
      }
      this.emailConfirmModal.isShowPopup = false;
    },
    proceedToMultiRegistration(action) {
      if (action === "Continue") {
        Vue.set(this.candidate, "share_profile", true);
        Vue.set(this.payload, "share_profile", true);
      }
      if (!action) {
        Vue.set(this.candidate, "email", "");
        Vue.set(this.payload, "email", "");
      }
      this.alreadyRegisteredModal.isShowPopup = false;
    },
    pad(n, width, z) {
      z = z || "0";
      n = n + "";
      return n.length >= width
        ? n
        : new Array(width - n.length + 1).join(z) + n;
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name == hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        } else if (name == hasError.whatsapp) {
          this.$refs.whatsapp.errors.clear;
          this.$refs.whatsapp.reset();
        } else if (name == hasError.month_awarded) {
          this.$refs.month_awarded.errors.clear;
          this.$refs.month_awarded.reset();
        }
      });
    },
    handleSlider: debounce(function (value) {
      Vue.set(this.candidate, "main_qualification_course_duration", value);
      Vue.set(this.payload, "main_qualification_course_duration", value);
    }, 500),
    handleAcquiredDate(name, value) {
      this[name] = value;
      let acquired_date = value.split("-");
      let spl_year = "specialist_registration_year";
      let spl_mth = "specialist_registration_month";
      Vue.set(this.payload, spl_year, parseInt(acquired_date[0]));
      Vue.set(this.payload, spl_mth, parseInt(acquired_date[1]));
    },
    showTermPopup() {
      let data = {
        document_ext: "PDF",
        link: "/doc/Talentfind_privacy_policy.pdf",
      };
      if (data.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(data.link);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.modalTitle = "Terms and Privacy Policy";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    preview(data) {
      this.previewModal.modalTitle = "Document Preview";
      let src = URL.createObjectURL(data.file);
      if (
        data.fileExt === "PNG" ||
        data.fileExt === "JPG" ||
        data.fileExt === "JPEG" ||
        data.fileExt === "GIF"
      ) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.pdf.ispdf = false;
        this.iframe.src = src;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (data.fileExt === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(src);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      }
    },
    previewTemp(data) {
      const { fileName } = data;
      let payload = { uuid: this.randomUuid, file_name: fileName };
      this.getTempDocs(payload).then((res) => {
        console.log('res: ', res);
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          generateLocalFileUrl(encodeURIComponent(res));
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      });
      this.previewModal.modalTitle = "Document Preview";
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    termmodalCallBack(action) {
      this.termConfirmModal.isShowPopup = false;
    },
    deleteFields(deleteFields) {
      deleteFields.forEach((field) => {
        delete this.payload[field];
      });
    },
    camelize(str) {
      return _.startCase(str);
    },
    handleExistingCandidateInput(name, value) {
      let val = value;
      if (name === "email") val = value.toLowerCase();
      Vue.set(this.existingCandidate, name, val);
      // if (name === "email") {
      //   Vue.set(this.candidate, "email", value);
      //   Vue.set(this.candidate, "alternate_email", value);
      //   Vue.set(this.payload, "email", value);
      //   Vue.set(this.payload, "alternate_email", value);
      // }
    },
    onExistingCandidate() {
      const isValid = this.$refs.existingCandidatePassword.validate();
      const isPasswordEntered = this.existingCandidate?.password;
      if (isPasswordEntered) {
        let payload = {
          ...this.existingCandidate,
          customer_uid: this.customer,
          organisation_id: this.organisationID ? this.organisationID : null,
          location_id: this.locationID ? this.locationID : null,
        };
        this.registerCommonCandidate(payload);
      }
    },
    checkCandidateExists() {
      this.isLoading = true;
      if (!this.existingCandidate["email"]) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Enter valid email!",
        });
        this.isLoading = false;
        return false;
      }
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(this.existingCandidate["email"]).toLowerCase())) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Enter valid email!",
        });
        this.isLoading = false;
        return false;
      }
      const payload = {
        email: this.existingCandidate["email"],
        customer_uid: this.customer,
        organisation_uid: this.organisationID,
      };
      this.getRegisteredHospitalsByEmail(payload).then((res) => {
        this.isLoading = false;
        const errMsg = res?.response?.data?.detail?.message || res?.response?.data?.detail;
        if (res?.response?.status === 409) {
          let message = res?.response?.data?.detail;
          this.showToast({
            class: "bg-danger text-white",
            message,
          });
        } else if (
          res?.response?.status === 400 &&
          errMsg?.includes("Already registered")
        ) {
          this.displayPage = 3;
          let message = errMsg;
          if (this.getOrgName)
            message = `You are already registered with ${this.getOrgName}`;
          this.showToast({
            class: "bg-danger text-white",
            message,
          });
        } else {
          let data = {
            email: this.existingCandidate["email"],
            customer_uid: this.customer,
          };
          this.getRegisteredHospitalsByEmail(data).then((res) => {
            if (this.getRegisteredParent?.length) {
              if (this.getRegisteredParent == "OHR") {
                this.displayPage = 3;
                this.showToast({
                  class: "bg-danger text-white",
                  message: `You are already registered with ${this.getRegisteredParent} `,
                });
              } else this.displayPage = 2;
            } else {
              this.displayPage = 4;
            }
          });
        }
      });
    },

    create(data) {
      this.isSubmitted = true;
      let { payload, docFiles } = data;
      let appendAction = [this.createCandidate(payload)];
      appendAction = [...appendAction, this.deleteTempDocs(this.randomUuid)];
      Promise.all(appendAction)
        .then(async (res) => {
          const { customer_uid, candidate_uid, user_id } =
            this.candidateProfile;
          docFiles.forEach(async (element) => {
            let { file, docType } = element;
            let { code: document_type } = docType;
            payload = {
              file,
              document_type,
              customer_uid,
              candidate_uid,
              user_id,
            };
            if (document_type === 1) {
              await this.multipleFileUpload(payload);
            } else this.multipleFileUpload(payload);
          });
          this.updatePhoto();
          this.clearCampaignFieldsData();
          // After complete Registration Route to campaign Questions
          if (!(_.isEmpty(this.regCompletedCandidate))) {
            // let { path_acronym } = getLocalOrgDetail();
            let payload = {
              email:  this.existingCandidate["email"],
              updated_by: candidate_uid,
            }
            this.updateCampaignPreRegCompleted(payload);
            // let path = `/${path_acronym}/campaign/${this.regCompletedCandidate?.campaign_uuid}`;
            // this.$router.push({ path });
          }

          // Auto login if user has query parameters for autologin and redirect_url from JOBS PAGE
          let { autologin, redirect_url } = this.$route.query;
          if(autologin) {
            const username = payload.email;
            const password = payload.password;  
            if (username && password) {
              this.isEmailBelongsToAcronym(username.toLowerCase().trim()).then(
                (res) => {
                  if (res.data) {
                    this.login({ username: username, password: password }).then((res) => {
                      this.$router.push(redirect_url)
                    });
                  }
                }
              );
            }
          }

          if(this.getUserData && Object.keys(this.getUserData).length > 0) {
            this.$router.push({ path: '/candidate-list' });
          }
        })
        .finally(() => {
          this.isSubmitted = false;
        });
    },
    upload(payload) {
      this.profilePhoto = payload;
      this.$store.commit("SET_UPLOADED");
    },
    updatePhoto() {
      let payload = this.profilePhoto;
      const document_type = payload.document_type || 1;
      const { customer_uid, candidate_uid, user_id } = this.candidateProfile;
      if (payload && payload.name) {
        if (payload.isPhotoUpload) {
          this.uploadPhoto({
            customer_uid,
            candidate_uid,
            user_id,
            [payload.name]: payload.value,
            document_type,
            ["fileName"]: payload.document_name,
            blobData: payload?.blobData,
          });
        }
      }
    },
    redirectToLogin() {
      this.$router.push({ path: LOGIN_URL() });
    },
    redirectToUserRegister() {
      this.resetCandidate();
    },
    checkOptionsAndValuesForMultiSelect(name, option) {
      let data = [];
      if (this.candidate[name]?.length) {
        let options = this.options[option];
        if (name == "preferred_country_ids") {
          options = this.preferredCountry;
        }
        this.candidate[name].forEach((val) => {
          let hasFound = options.some((option) => option.code == val.code);
          if (hasFound) data.push(val);
        });
        this.handleChangeMultiSelectAsArray(name, data);
      }
    },
    fetchCourse(id) {
      if ([1, 4].includes(id)) {
        this.fetchCourseQualification({
          candidate_type_id: id,
          qualification_classification: 'First Degree'
        });
      }
      else if ([2, 3].includes(id)) {
        this.fetchCourseQualification({
          candidate_type_id: id,
          qualification_classification: ['First Degree', 'Diploma']
        });
      }
    },
    fetchSpeciality(id) {
      if (id === 2) {
        this.fetchCourseSpeciality({
          candidate_type_id: id,
          is_training: true
        });
      }
      else if (id === 3) {
        this.fetchCourseSpeciality({
          candidate_type_id: id
        });
      }
    },
    sortOrganisationRegions(data) {
      data =
        data?.sort((a, b) => {
          if (a.display_order === b.display_order) return 0;
          else if (a.display_order === null) return 1;
          else if (b.display_order === null) return -1;
          if (a.display_order < b.display_order) return -1;
          else if (a.display_order > b.display_order) return 1;
          return 0;
        }) || [];
      return [{ code: 0, label: "All" }, ...data];
    },
    // campaignModalCallBack(action) {
    //   if (action === false || action === "OK") {
    //     this.campaignModal.isShowPopup = false;
    //   }
    // },
    notifyModalCallBack(action) {
      const newVal = this.notifyModal.newVal;
      const name = this.notifyModal.name;
      if(action === "Confirm") {
        Vue.set(this.candidate,name,newVal)
        this.payload[name] = newVal
      } else {
        const deleteNurseFields = [
          "candidate_type_id",
          "main_qualification_country_id",
          "main_qualification_id",
          "qualified_date",
          "speciality_id",
          "first_qualified_date",
          "main_qualification_description",
          "sub_speciality_id",
          "level_id",
          "first_qualification_country_id",
          "band_id",
          "is_have_higher_qualification",
          "any_higherqualification_related_to_practice",
          "speciality_duration",
          "license_country_id",
          "license_level_id",
          "speciality_nurse_or_nurse_practitioner",
          "student_country_id",
          "student_qualification_id",
          "course_start_date",
          "course_end_date",
          "student_course_mode",
          "student_distance_learning",
          "is_sponsored",
          "sponsorship_start_date",
          "sponsorship_end_date",
          "student_speciality_id",
          "other_areas_of_expertise_ids" ,
          "is_additional_course",
          "course_name",
          "training_course_start_date",
          "training_course_end_date",
          "additional_sponsorship_start_date",
          "additional_sponsorship_end_date",
          "language_exam",
          "is_additional_training_sponsored",
          "language_options"


        ];
        deleteNurseFields.forEach((field) => {
          delete this.candidate[field]
          delete this.payload[field];
          Vue.set(this.candidate,name,'')
          this.payload[name] = ''
        });
        if(name === "is_student") {
          Vue.set(this.candidate,name,newVal)
          this.payload[name] = newVal
        }
      
      }
      this.notifyModal.isShowPopup = false;
    }   
  },
};
